import { Controller } from "stimulus"

export default class extends Controller {
  static values = { 
    state: {type: String, default: 'open' }
  }

  static classes = [ "open", "closed" ]

  connect() {
    if (this.stateValue == "closed"){
      this.container().hide()
    }
  }

  disconnect() {
  }

  toggle(e) {
    e.preventDefault()

    this.toggleIcon()
    this.toggleState()
    this.container().slideToggle()
  }

  toggleIcon() {
    if (this.stateValue == "open"){
      this.icon().classList.replace(this.openClass, this.closedClass)
    }else{
      this.icon().classList.replace(this.closedClass, this.openClass)
    }
  }

  toggleState() {
    if (this.stateValue == "open"){
      this.stateValue = "closed"
    }else{
      this.stateValue = "open"
    }
  }

  container() {
    return $(this.element.closest(".box").querySelector(".box-content"))
  }

  icon() {
    return this.element.querySelector("i.fa")
  }
}
