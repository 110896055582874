import { Controller } from "stimulus"

export default class extends Controller {
  static values = { refreshUrl: String }

  connect() {
    this.photosPane = $(this.element)
    this.initializePhotoViewer()
  }

  disconnect() {
    this.photosPane.data('viewer').destroy()
  }

  refresh() {
    if (this.refreshUrlValue != null){
      Rails.ajax({
        url: this.refreshUrlValue,
        type: 'GET',
        dataType: 'script',
        success: () => this.update()
      })
    }
  }

  initializePhotoViewer() {
    this.photosPane.viewer({
      movable: false,
      title: [true, (image, imageData) => image.alt],
      url: 'src',
      toolbar: {
        zoomIn: true,
        zoomOut: true,
        oneToOne: false,
        reset: true,
        prev: true,
        play: true,
        next: true,
        rotateLeft: true,
        rotateRight: true,
        flipHorizontal: false,
        flipVertical: false
      }
    })
  }

  update() {
    this.photosPane.data('viewer').update()
  }
}
