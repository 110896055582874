import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "conditionalFieldset" ]

  connect(){
    this.refresh()
  }

  refresh(e){
    for (const fieldset of this.conditionalFieldsetTargets) {
      this.processFieldset(fieldset)
    }
  }

  processFieldset(fieldset){
    fieldset.disabled = !this.conditionsPassed(fieldset)
  }

  conditionsPassed(fieldset){
    const conditions = JSON.parse(fieldset.dataset.conditions)

    return (conditions.filter(condition => this.conditionPassed(condition)).length > 0)
  }

  conditionPassed({ field, type, values, not_values }){
    let currentValue = this.getCurrentFieldValue(field, type)

    if (Array.isArray(currentValue)){
      if (not_values){
        return !this.commonElementsPresent(not_values, currentValue)
      }else{
        return (currentValue.length == 0 || this.commonElementsPresent(values, currentValue))
      }
    }else{
      if (not_values){
        return !not_values.includes(this.getCurrentFieldValue(field, type))
      }else{
        return values.includes(this.getCurrentFieldValue(field, type))
      }
    }
  }

  getCurrentFieldValue(field, type){
    if (type == 'checkbox'){
      let checkboxes = this.element.elements[field]
      let selected = []

      for (var i = 0; i < checkboxes.length; i++){
        if (checkboxes[i].checked) {
          selected.push(checkboxes[i].value)
        }
      }
      return selected
    }else{
      return Object.fromEntries(new FormData(this.element))[field]
    }
  }

  commonElementsPresent(arrayA, arrayB){
    return (arrayA.filter(x => arrayB.includes(x)).length > 0)
  }
}
